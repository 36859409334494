import {LitElement, html, css} from 'lit';
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

export class CanvasElement extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
      }
    `;
  }

  constructor() {
    super();
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer();

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({color: 0x00ff00});
    this.cube = new THREE.Mesh(geometry, material);
    this.scene.add(this.cube);

    // https://stackoverflow.com/questions/29916886/three-js-add-plane-to-the-scene
    // let groundPlane = new THREE.PlaneGeometry(100, 100);
    // groundPlane.rotateX(- Math.PI / 2);
    // let grayMaterial = new THREE.MeshBasicMaterial({color: "gray", side: THREE.DoubleSide});
    // this.groundPlane = new THREE.Mesh(groundPlane, grayMaterial);

    // this.scene.add(this.groundPlane);

    this.camera.position.z = 2;

    this.controls = new OrbitControls( this.camera,  this.renderer.domElement );
    this.controls.update();
  }

  async connectedCallback() {
    super.connectedCallback(); // Add this line
    await this.updateComplete;
    // Add a small delay to ensure styles are applied
    requestAnimationFrame(() => {
        const rect = this.getBoundingClientRect();
        this.renderer.setSize(rect.width, rect.width);
    });
  }

  firstUpdated() {
    this.shadowRoot.appendChild(this.renderer.domElement);
    
    const animate = () => {
      requestAnimationFrame(animate);
      this.controls.update();
      this.cube.rotation.x += 0.01;
      this.cube.rotation.y += 0.01;
      this.renderer.render(this.scene, this.camera);
    };
    animate();
  }

  render() {
    return html``;
  }
}

window.customElements.define('canvas-element', CanvasElement);
